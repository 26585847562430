import { useState, useEffect, useContext, useCallback, useMemo, useRef } from 'react';
import { GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { useForm } from 'react-hook-form';
import useAuth from 'auth/UseAuth';
import { useFilterState } from 'components/hooks/UseFilterState';
import PipelineRulesContext from '../../../../contexts/PipelineRulesContext';
import { LoadingState, ErrorManagement, LoadState } from '../../../../components/LoadingStateUtil';
import {
  CreatePipelineRules,
  UpdatePipelineRule,
  DeletePipelineRule,
  GetPipelineRules,
} from '../../../../data/SuperDuperFiestaData';
import {
  PipelineRule,
  SuperDuperFiestaEntity,
  SuperDuperFiestaPipeline,
  SuperDuperFiestaStage,
  SuperDuperFiestaDataSource,
  PipelineRulesConfig,
} from '../../../../data/SuperDuperFiestaData';
import { buildRule, updateRows, removeFromList, transformRules } from '../pipelineRulesUtils';
import { IRuleFormInput, defaultFormValues } from '../../../../contexts/PipelineRulesContext';
import { rowModificationName } from 'util/Constants';

export const usePipelineRules = ({
  selectedEntity,
  selectedPipeline,
  selectedStage,
  allRows = [],
  selectedRow,
  setSelectedRow,
  clonedRules,
  setRuleCloneSelection,
  open,
  setOpen,
  selectedRowIds,
  setRowSelection,
}: {
  selectedEntity: SuperDuperFiestaEntity | null;
  selectedPipeline: SuperDuperFiestaPipeline | null;
  selectedStage: SuperDuperFiestaStage | null;
  allRows: PipelineRule[];
  selectedRow: PipelineRule | null;
  setSelectedRow: (row: PipelineRule | null) => void;
  clonedRules: PipelineRule[];
  setRuleCloneSelection: React.Dispatch<React.SetStateAction<GridRowSelectionModel>>;
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  selectedRowIds: string[];
  setRowSelection: React.Dispatch<React.SetStateAction<GridRowSelectionModel>>;
}) => {
  const { accessToken } = useAuth();
  const { setRefreshData } = useContext(PipelineRulesContext);
  const { filterState, filtersDispatch, getFilterData } = useFilterState({
    includeEntities: true,
    includeStages: true,
    includeDataClasses: true,
    includeDataSources: true,
    includeIssueTypes: true,
    includePipelines: true,
    includeStepTypes: true,
    includeIssues: true,
  });

  const [loadingState, setLoadingState] = useState<LoadingState>({ status: 'NotStarted' });
  const [rows, setRows] = useState<PipelineRule[]>([]);
  const [isNew, setIsNew] = useState<boolean>(true);
  const [newStepIds, setNewStepIds] = useState<string[]>([]);
  const [editedStepId, setEditedStepId] = useState<string | null>(null);
  const [selectedDataSources, setSelectedDataSources] = useState<SuperDuperFiestaDataSource[]>([]);

  const {
    handleSubmit,
    reset,
    control,
    setValue,
    watch,
    trigger,
    formState: { isDirty, isValid },
  } = useForm<IRuleFormInput>({
    defaultValues: defaultFormValues,
    mode: 'onChange',
  });

  const formPipeline = watch('pipeline');
  const watchStepType = watch('stepType');
  const watchExecutionOrder = watch('executionOrder');

  const filtersInitRef = useRef(false);
  const rulesInitRef = useRef(false);

  useEffect(() => {
    if (!open) {
      filtersInitRef.current = false;
      return;
    }

    if (!filtersInitRef.current) {
      filtersInitRef.current = true;
      getFilterData();
    }
  }, [open, getFilterData]);

  useEffect(() => {
    if (!open) return;
    setIsNew(selectedRow === null && editedStepId === null);
  }, [selectedRow, editedStepId, open]);

  // Determines if rules should be fetched
  // Modal is open
  // Stage and Entity are selected
  // No existing rows are present
  // Pipeline is selected in form but not in parent component
  const shouldFetchRules = useMemo(() => {
    return (
      open &&
      selectedStage !== null &&
      selectedEntity !== null &&
      allRows.length === 0 &&
      formPipeline &&
      selectedPipeline === null
    );
  }, [open, selectedStage, selectedEntity, allRows.length, formPipeline, selectedPipeline]);

  // Fetches rules when creating a new rule and no rows were provided
  useEffect(() => {
    if (!shouldFetchRules || rulesInitRef.current) return;

    if (!formPipeline) return;

    return LoadState(setLoadingState, async () => {
      rulesInitRef.current = true;
      const queryParams: PipelineRulesConfig = {
        pipelineId: formPipeline.pipelineId,
        stageId: selectedStage!.stageId,
        entityId: selectedEntity!.entityId,
      };

      const data = await GetPipelineRules(queryParams, accessToken);
      setRows(transformRules([...data]));
    });
  }, [shouldFetchRules, formPipeline, selectedStage, selectedEntity, accessToken]);

  // Sets form data for editing a rule
  useEffect(() => {
    if (!open || selectedRow === null) return;

    const dataSource = filterState.dataSources?.find(ds => ds.displayName === selectedRow.dataSourceName);
    const editRule = {
      id: selectedRow.id,
      executionOrder: selectedRow.executionOrderDisplay,
      ruleName: selectedRow.name,
      description: selectedRow.description || '',
      queryCondition: selectedRow.queryCondition || '',
      queryJoin: selectedRow.queryJoin || '',
      queryGroupBy: selectedRow.queryGroupBy || '',
      needsReview: selectedRow.needsReview,
      active: selectedRow.active,
      pipeline: selectedPipeline,
      entity: selectedEntity,
      stage: selectedStage,
      stepUpdateValues: selectedRow.stepUpdateValues,
      stepType: filterState.stepTypes?.find(st => st.displayName === selectedRow.stepTypeName),
      dataClass: filterState.dataClasses?.find(dc => dc.name === selectedRow.dataClassName),
      issue: filterState.issues?.find(dc => dc.name === selectedRow.issueName),
    };

    setSelectedDataSources(!dataSource ? [] : [dataSource]);
    reset(editRule);
  }, [open, selectedRow, filterState, selectedEntity, selectedPipeline, selectedStage, reset]);

  // Sets the rows for the modal
  useEffect(() => {
    if (allRows === null || allRows.length === 0) return;

    if (!open || !filterState.dataSources) return;

    const filteredRows = transformRules([...allRows]);

    const { stepTypes, dataClasses, issues, dataSources } = filterState;
    if (!stepTypes || !dataClasses || !issues || !dataSources) return;

    const builtRules = clonedRules.map(rule => {
      const stepType = stepTypes?.find(st => st.displayName === rule.stepTypeName);
      const dataClass = dataClasses?.find(dc => dc.name === rule.dataClassName);
      const issue = issues?.find(dc => dc.name === rule.issueName);
      const dataSource = dataSources?.find(ds => ds.displayName === rule.dataSourceName);

      return {
        ...rule,
        pipeline: selectedPipeline,
        entity: selectedEntity,
        stage: selectedStage,
        stepUpdateValues: rule.stepUpdateValues,
        stepTypeId: stepType?.stepTypeId,
        stepType,
        dataClassId: dataClass?.dataClassId,
        dataClass,
        issueId: issue?.issueId,
        issue,
        dataSourceId: dataSource?.dataSourceId,
        dataSource,
      };
    });

    const orderedRows = updateRows(builtRules, null, filteredRows);
    const newRowStepIds = builtRules.map(r => r.stepId);

    setNewStepIds(prev => [...prev, ...newRowStepIds].filter(r => r !== null));
    setRows(orderedRows);
  }, [allRows, clonedRules, open, filterState, selectedEntity, selectedPipeline, selectedStage]);

  // Checks for duplicate rules based on rule name, active, and data source
  const hasDuplicateRules = useMemo(() => {
    const ruleMap = new Map<string, Set<string>>();

    for (const rule of rows) {
      const key = `${rule.name}|${rule.dataSourceId}|${rule.active}`;
      if (!ruleMap.has(key)) {
        ruleMap.set(key, new Set());
      }
      ruleMap.get(key)!.add(rule.stepId);
    }

    return Array.from(ruleMap.values()).some(stepIds => stepIds.size > 1);
  }, [rows]);

  const hasInvalidStepTypeOrder = useMemo(() => {
    if (!watchStepType?.name || !watchExecutionOrder) return false;
    if (watchStepType.name !== rowModificationName) return false;
    if (watchExecutionOrder <= 1) return false;

    const sortedRows = [...rows].sort((a, b) => a.executionOrder - b.executionOrder);
    const eoIndex = sortedRows.findIndex(row => row.executionOrder === Number(watchExecutionOrder) - 1);

    if (eoIndex === -1) return false;

    return sortedRows.slice(0, eoIndex).some(row => row.stepTypeName !== rowModificationName);
  }, [rows, watchStepType, watchExecutionOrder]);

  // Resets the form and clears selected data
  const clearForm = useCallback(() => {
    reset(defaultFormValues);
    setSelectedDataSources([]);
    setSelectedRow(null);
    setValue('pipeline', selectedPipeline);
    setValue('entity', selectedEntity);
    setValue('stage', selectedStage);
  }, [reset, setSelectedRow, setValue, selectedPipeline, selectedEntity, selectedStage]);

  // Opens the modal and initializes form data
  const handleOpen = useCallback(() => {
    setValue('pipeline', selectedPipeline);
    setValue('entity', selectedEntity);
    setValue('stage', selectedStage);

    if (Array.isArray(selectedRowIds) && selectedRowIds.length > 0) {
      setValue('queryCondition', `row_id IN ('${selectedRowIds.join("','")}')`);
    }
    setOpen(true);
  }, [setValue, selectedPipeline, selectedEntity, selectedStage, setOpen, selectedRowIds]);

  // Closes the modal and resets all state
  const handleClose = useCallback(() => {
    setOpen(false);
    clearForm();
    setRows([]);
    setNewStepIds([]);
    setEditedStepId(null);
    setRuleCloneSelection([]);
    setRowSelection([]);
    setLoadingState({ status: 'NotStarted' });
    rulesInitRef.current = false;
  }, [
    clearForm,
    setOpen,
    setRows,
    setNewStepIds,
    setEditedStepId,
    setRuleCloneSelection,
    setRowSelection,
    setLoadingState,
    rulesInitRef,
  ]);

  // Handles adding a new rule or updating an existing one
  const handleAddOrUpdateRule = useCallback(
    (data: IRuleFormInput) => {
      const firstDataSource = selectedDataSources[0];
      if (isNew) {
        let newRows: PipelineRule[] = [];
        const newRowsParams = buildRule(data, null, rows, firstDataSource);

        if (newRowsParams === undefined) {
          return;
        }

        if (selectedDataSources.length > 0 && selectedDataSources.length < filterState.dataSources?.length) {
          selectedDataSources.forEach((ds, idx) => {
            newRows.push({
              ...newRowsParams,
              id: newRowsParams.id + idx,
              executionOrder: newRowsParams.executionOrder + idx,
              executionOrderDisplay: newRowsParams.executionOrder + idx,
              dataSourceName: ds.displayName,
              dataSourceId: ds.dataSourceId,
            });
          });
        } else {
          newRows.push({
            ...newRowsParams,
            dataSourceName: null,
            dataSourceId: null,
          });
        }

        const orderedRows = updateRows(newRows, null, rows);
        const newRowStepIds = newRows.map(r => r.stepId);

        setNewStepIds(prev => [...prev, ...newRowStepIds].filter(r => r !== null));
        setRows(orderedRows);
      } else {
        if (selectedRow === null || selectedRow.stepId === undefined) {
          return;
        }
        const updatedRowsParams = buildRule(data, selectedRow, rows, firstDataSource);

        if (updatedRowsParams === undefined) {
          return;
        }

        const updatedRule = { ...selectedRow, ...updatedRowsParams };
        const currentIndex = rows.findIndex(r => r.stepId === selectedRow.stepId);
        const orderedRows = updateRows([updatedRule], currentIndex, rows);

        if (newStepIds.includes(updatedRowsParams.stepId)) {
          setSelectedRow(null);
        } else {
          setEditedStepId(updatedRowsParams.stepId);
        }
        setRows(orderedRows);
      }
      clearForm();
    },
    [
      isNew,
      selectedDataSources,
      filterState.dataSources,
      newStepIds,
      rows,
      selectedRow,
      clearForm,
      setNewStepIds,
      setRows,
      setSelectedRow,
      setEditedStepId,
    ]
  );

  // Saves new rules to the backend
  const handleSave = useCallback(async () => {
    ErrorManagement('Loading', setLoadingState, async () => {
      if (!selectedStage || newStepIds.length === 0) {
        throw new Error('Select a stage and add at least one new rule before saving');
      }

      const newRules = rows.filter(r => newStepIds.includes(r.stepId));
      const pipeline = selectedPipeline || filterState.pipelines?.find(p => p.pipelineId === newRules[0].pipelineId);
      if (!pipeline) {
        throw new Error('Pipeline not found');
      }

      const queryParams: PipelineRulesConfig = {
        pipelineId: pipeline.pipelineId,
        stageId: selectedStage.stageId,
      };
      await CreatePipelineRules(newRules, queryParams, accessToken);
      handleClose();
      setRefreshData(true);
    });
  }, [
    selectedPipeline,
    selectedStage,
    newStepIds,
    rows,
    accessToken,
    handleClose,
    setRefreshData,
    filterState.pipelines,
  ]);

  // Updates an existing rule in the backend
  const handleUpdate = useCallback(async () => {
    ErrorManagement('Loading', setLoadingState, async () => {
      const editedRule = rows.find(r => r.stepId === editedStepId);
      if (selectedPipeline && selectedStage && editedStepId && editedRule) {
        const queryParams: PipelineRulesConfig = {
          pipelineId: selectedPipeline.pipelineId,
          stageId: selectedStage.stageId,
        };
        await UpdatePipelineRule(editedRule.stepId, editedRule, queryParams, accessToken);
        handleClose();
        setRefreshData(true);
      }
    });
  }, [selectedPipeline, selectedStage, editedStepId, rows, accessToken, handleClose, setRefreshData]);

  // Deletes a rule from the backend
  const handleDelete = useCallback(async () => {
    ErrorManagement('Loading', setLoadingState, async () => {
      if (selectedRow !== null && selectedRow.stepId !== null) {
        await DeletePipelineRule(selectedRow.stepId, accessToken);
        handleClose();
        setRefreshData(true);
      }
    });
  }, [selectedRow, accessToken, handleClose, setRefreshData]);

  // Removes a row from the local state
  const removeRow = useCallback(
    (stepId: string) => {
      const newRows = removeFromList(stepId, rows);
      if (newRows) {
        setRows(newRows);
      }

      setNewStepIds(prev => prev.filter(r => r !== stepId));
    },
    [rows]
  );

  // Sets a row as selected for editing
  const editRow = useCallback(
    (stepId: string) => {
      const selectedRule = rows.find(r => r.stepId === stepId);
      if (selectedRule) {
        setSelectedRow(selectedRule);
      }
    },
    [rows, setSelectedRow]
  );

  // Handles form submission
  const onSubmit = useCallback(
    (data: IRuleFormInput) => {
      handleAddOrUpdateRule(data);
    },
    [handleAddOrUpdateRule]
  );

  return {
    loadingState,
    rows,
    setRows,
    open,
    setOpen,
    isNew,
    newStepIds,
    editedStepId,
    selectedDataSources,
    setSelectedDataSources,
    hasDuplicateRules,
    hasInvalidStepTypeOrder,
    handleOpen,
    handleClose,
    handleAddOrUpdateRule,
    handleSave,
    handleUpdate,
    handleDelete,
    removeRow,
    editRow,
    control,
    trigger,
    watch,
    setValue,
    isDirty,
    isValid,
    handleSubmit,
    onSubmit,
    clearForm,
    filterState,
    filtersDispatch,
    formState: { isDirty, isValid },
  };
};
