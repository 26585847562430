import React, { useState } from 'react';
import { AuthorizedPage } from 'auth/AuthorizedPage';
import { Box, useTheme, IconButton, Divider } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Page } from 'components/Page';
import useMemoTranslation from '../hooks/UseMemoTranslation';
import { PageContent } from '../components/PageContent';
import { PipelineReview } from './components/PipelineReview';
import { PipelineReviewGrid } from './components/PipelineReviewGrid';
import { FlexTableBox } from '../components/FlexTableBox';
import { LoadingIndicator } from '../components/LoadingIndicator';
import { ErrorIndicator } from '../components/ErrorIndicator';
import { LoadingState } from '../components/LoadingStateUtil';
import { PipelineReviewContextProvider } from '../contexts/PipelineReviewContext';

export const PipelineReviewPage = () => {
  const { t } = useMemoTranslation();
  const theme = useTheme();

  const [open, setOpen] = useState(true);
  const [loadingState, setLoadingState] = useState<LoadingState>({ status: 'NotStarted' });

  return (
    <AuthorizedPage hasSuperDuperFiestaAccess>
      <Page title={t('pipelineReview')}>
        <PipelineReviewContextProvider>
          <PageContent>
            <Box sx={{ display: 'flex', position: 'relative', height: '100%' }}>
              <Box sx={{ width: '25%', flexShrink: 0, display: open ? 'block' : 'none' }}>
                <PipelineReview loadingProps={[loadingState, setLoadingState]} />
              </Box>
              <Divider orientation={'vertical'} sx={{ ml: 2, mr: 2, display: open ? 'block' : 'none' }} />
              <IconButton
                onClick={() => setOpen(!open)}
                sx={{
                  position: 'absolute',
                  left: open ? `calc(25% + ${theme.spacing(4)})` : 0,
                  top: 0,
                  zIndex: 1,
                }}
              >
                {open ? <ChevronLeftIcon /> : <MenuIcon />}
              </IconButton>
              <Box sx={{ flexGrow: 1, overflow: 'hidden', width: '100%' }}>
                <FlexTableBox sx={{ width: '100%', minWidth: 0 }}>
                  <PipelineReviewGrid loadingProps={[loadingState, setLoadingState]} />
                  <Box>
                    <LoadingIndicator loadingState={loadingState} margin={'T'} />
                    <ErrorIndicator loadingState={loadingState} />
                  </Box>
                </FlexTableBox>
              </Box>
            </Box>
          </PageContent>
        </PipelineReviewContextProvider>
      </Page>
    </AuthorizedPage>
  );
};
