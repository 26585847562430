import React, { useEffect, useMemo, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { Box, IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import {
  GridColDef,
  GridPagination,
  GridRowId,
  GridRowSelectionModel,
  GridToolbarFilterButton,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { FlexBox } from 'components/FlexBox';
import { FlexTableBox } from 'components/FlexTableBox';
import { GridExportButton } from 'components/GridExportButton';
import { CompactGridWrapper } from 'components/grid/CompactGridWrapper';
import { renderCellBooleanCheck } from 'components/grid/GridCellBooleanCheck';
import { cloneDeep } from 'lodash';
import { v4 as uuid } from 'uuid';
import { PipelineRule, PipelineRulesConfig } from 'data/SuperDuperFiestaData';
import useAuth from '../../auth/UseAuth';
import { LoadState, LoadingProps } from '../../components/LoadingStateUtil';
import { GetPipelineRules } from '../../data/SuperDuperFiestaData';
import useMemoTranslation from '../../hooks/UseMemoTranslation';
import {
  createdAt,
  createdBy,
  dataClassName,
  dataSourceName,
  entityName,
  executedAt,
  executionDurationMs,
  executionOrder,
  field,
  issueName,
  nameField,
  needsReview,
  queryCondition,
  queryGroupBy,
  queryJoin,
  rowsAffected,
  state,
  stepTypeName,
  updatedBy,
  value,
} from '../../util/Constants';
import { dateGetter, formatMsToHHMMSS } from '../../util/grid/TableUtils';
import { CloneRulesModal } from './CloneRulesModal';
import { PipelineRulesModal } from './PipelineRulesModal/PipelineRulesModal';
import { usePipelineRulesContext } from '../../contexts/PipelineRulesContext';

export const PipelineRulesGrid = ({ loadingProps }: { loadingProps: LoadingProps }) => {
  const { accessToken } = useAuth();
  const apiRef = useGridApiRef();
  const { t } = useMemoTranslation();
  const { refreshData, setRefreshData, selectedEntity, selectedPipeline, selectedStage } = usePipelineRulesContext();

  const [, setLoadingState] = loadingProps;

  const [rows, setRows] = useState<PipelineRule[]>([]);
  const [selectedRow, setSelectedRow] = useState<PipelineRule | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [ruleCloneSelection, setRuleCloneSelection] = useState<GridRowSelectionModel>([]);
  const [clonedRules, setClonedRules] = useState<PipelineRule[]>([]);

  const columns = useColumns(setSelectedRow, setOpen);

  useEffect(() => {
    if (selectedEntity !== null && selectedPipeline !== null && selectedStage !== null) {
      setIsValid(true);
      setRefreshData(true);
    } else {
      setIsValid(false);
    }
  }, [selectedPipeline, selectedStage, selectedEntity, setRefreshData]);

  useEffect(() => {
    if (ruleCloneSelection.length === 0) {
      setClonedRules([]);
    }
  }, [ruleCloneSelection]);

  useEffect(() => {
    return LoadState(setLoadingState, async () => {
      if (!accessToken) {
        return;
      }

      if (refreshData && selectedEntity !== null && selectedPipeline !== null && selectedStage !== null) {
        const queryParams: PipelineRulesConfig = {
          pipelineId: selectedPipeline.pipelineId,
          stageId: selectedStage.stageId,
          entityId: selectedEntity.entityId,
        };

        const data = await GetPipelineRules(queryParams, accessToken);

        if (data.length === 0) {
          setRows([]);
        } else {
          setRefreshData(false);
          let rootIndex = 1;
          setRows(
            data.map((row, index) => {
              const isChild = Array.isArray(row.hierarchy) && row.hierarchy.length > 1;
              const executionOrderValue = isChild ? rootIndex - 1 : rootIndex++;

              return {
                ...row,
                id: index,
                hierarchy: Array.isArray(row.hierarchy) && row.hierarchy.length > 0 ? row.hierarchy : [`${index}`],
                executionOrder: executionOrderValue,
                executionOrderDisplay: executionOrderValue,
              };
            })
          );
        }
      } else {
        setRows([]);
      }
    });
  }, [setLoadingState, accessToken, refreshData, setRefreshData, selectedPipeline, selectedStage, selectedEntity]);

  const cloneEditRules = () => {
    var selectedRules = ruleCloneSelection
      .map((r: GridRowId) => rows.find(row => row.id === r))
      .filter(r => r !== undefined)
      .map(r => r!);
    var newRules: PipelineRule[] = [...cloneDeep(selectedRules)];
    newRules.forEach((r, i) => {
      const newIndex = rows.length + i;
      r.stepId = uuid();
      r.id = newIndex;
      r.executionOrder = newIndex + 1;
      r.executionOrderDisplay = newIndex + 1;

      if (r.stepUpdateValues) {
        r.stepUpdateValues = r.stepUpdateValues.map(value => ({
          ...value,
          stepUpdateValueId: uuid(),
        }));
      }
    });
    setClonedRules(newRules);
    setOpen(true);
  };

  function CustomToolbar() {
    return (
      <FlexBox flexDirection={'row-reverse'}>
        <GridPagination />
        <GridToolbarFilterButton />
      </FlexBox>
    );
  }

  function CustomNoRowsOverlay() {
    return (
      <Box
        sx={{ mt: 1 }}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        Select a pipeline
      </Box>
    );
  }

  return (
    <FlexTableBox sx={{ gap: 1 }}>
      <FlexTableBox>
        <FlexBox flexDirection={'row-reverse'}>
          <CloneRulesModal
            allRows={rows}
            setRuleCloneSelection={setRuleCloneSelection}
            cloneEditRules={cloneEditRules}
            ruleCloneSelection={ruleCloneSelection
              .map((r: GridRowId) => rows.find(row => row.id === r))
              .filter(r => r !== undefined)
              .map(r => r!)}
          />
          <GridExportButton apiRef={apiRef} fileName='pipeline_rules' />
          <PipelineRulesModal
            canOpen={isValid}
            open={open}
            setOpen={setOpen}
            allRows={rows}
            selectedRow={selectedRow}
            setSelectedRow={setSelectedRow}
            clonedRules={clonedRules}
            setRuleCloneSelection={setRuleCloneSelection}
            selectedEntity={selectedEntity}
            selectedPipeline={selectedPipeline}
            selectedStage={selectedStage}
          />
        </FlexBox>
        <CompactGridWrapper
          apiRef={apiRef}
          columns={columns}
          rows={rows}
          rowHeight={35}
          pagination
          pageSizeOptions={[100, 250, 500]}
          disableRowSelectionOnClick
          checkboxSelection
          isRowSelectable={params => params.row.hierarchy.length <= 1}
          sx={{
            '& .MuiDataGrid-cellCheckbox': {
              visibility: 'hidden',
              '& .MuiCheckbox-root:not(.Mui-disabled)': {
                visibility: 'visible',
              },
            },
          }}
          onRowSelectionModelChange={newRowSelection => {
            setRuleCloneSelection(newRowSelection);
          }}
          rowSelectionModel={ruleCloneSelection}
          slots={{
            footer: CustomToolbar,
            noRowsOverlay: CustomNoRowsOverlay,
          }}
          treeData
          getTreeDataPath={row => row?.hierarchy}
          groupingColDef={{
            headerName: t(executionOrder),
            width: 125,
            valueFormatter: params => apiRef.current.getRow(params.id!)?.executionOrderDisplay,
          }}
        />
      </FlexTableBox>
    </FlexTableBox>
  );
};

const useColumns = (
  setSelectedRow: React.Dispatch<React.SetStateAction<PipelineRule | null>>,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
): GridColDef[] => {
  const { t } = useMemoTranslation();

  return useMemo(() => {
    const CustomEditCell = (params: any) => {
      if (params.row.hierarchy.length <= 1) {
        return (
          <IconButton
            onClick={() => {
              setSelectedRow(params.row);
              setOpen(true);
            }}
            color='primary'
            sx={{ pr: 0 }}
          >
            <EditIcon fontSize='small' />
          </IconButton>
        );
      }
      return null;
    };

    return [
      {
        field: nameField,
        headerName: t(nameField),
        width: 250,
        renderCell: (params: any) => (
          <Tooltip title={params.row.description || params.value.toString()}>
            <span className='table-cell-trucate'>{params.value.toString()}</span>
          </Tooltip>
        ),
      },
      {
        field: stepTypeName,
        headerName: t(stepTypeName),
        width: 150,
      },
      {
        field: entityName,
        headerName: t(entityName),
        width: 150,
      },
      {
        field: dataSourceName,
        headerName: t(dataSourceName),
        width: 150,
      },
      {
        field: dataClassName,
        headerName: t(dataClassName),
        width: 150,
      },
      {
        field: issueName,
        headerName: t(issueName),
        width: 150,
      },
      {
        field: needsReview,
        headerName: t(needsReview),
        width: 100,
        align: 'center',
        renderCell: renderCellBooleanCheck,
      },
      {
        field: queryCondition,
        headerName: t(queryCondition),
        width: 250,
      },
      {
        field: queryJoin,
        headerName: t(queryJoin),
        width: 250,
      },
      {
        field: queryGroupBy,
        headerName: t(queryGroupBy),
        width: 150,
      },
      {
        field: field,
        headerName: t(field),
        width: 100,
      },
      {
        field: value,
        headerName: t(value),
        width: 100,
      },
      {
        field: createdBy,
        headerName: t(createdBy),
        width: 100,
      },
      {
        field: updatedBy,
        headerName: t(updatedBy),
        width: 100,
      },
      {
        field: createdAt,
        headerName: t(createdAt),
        type: 'dateTime',
        valueGetter: dateGetter,
        width: 200,
      },
      {
        field: rowsAffected,
        headerName: t(rowsAffected),
        width: 100,
      },
      {
        field: executionDurationMs,
        headerName: t('executionDuration'),
        valueGetter: params =>
          params.row.executionDurationMs ?? new Date().getTime() - new Date(params.row.executedAt + 'Z').getTime(),
        valueFormatter: ({ value }) => {
          if (isNaN(value)) {
            return '' as const;
          }
          return `${formatMsToHHMMSS(value)} -- (${(value as number).toLocaleString()} ms)`;
        },
        width: 200,
      },
      {
        field: state,
        headerName: t(state),
        width: 100,
      },
      {
        field: executedAt,
        headerName: 'Last Executed At',
        type: 'dateTime',
        valueGetter: dateGetter,
        width: 200,
      },
      {
        field: 'active',
        headerName: 'Active',
        width: 75,
        align: 'center',
        renderCell: renderCellBooleanCheck,
      },
      {
        field: 'action',
        width: 50,
        renderCell: CustomEditCell,
      },
    ];
  }, [t, setSelectedRow, setOpen]);
};
