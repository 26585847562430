import React, { useContext, useEffect, useState, useMemo, useCallback } from 'react';
import { LoadingProps, LoadState } from '../../components/LoadingStateUtil';
import useAuth from '../../auth/UseAuth';
import { FlexTableBox } from '../../components/FlexTableBox';
import { CompactGridWrapper } from '../../components/grid/CompactGridWrapper';
import {
  FilterParamsConfig,
  GetPipelineReview,
  PipelineReviewConfig,
  PipelineReviewRecord,
} from '../../data/SuperDuperFiestaData';
import {
  GridColDef,
  GridEventListener,
  GridFilterModel,
  GridLogicOperator,
  GridPagination,
  GridPreferencePanelParams,
  GridRenderCellParams,
  GridRowSelectionModel,
  GridSortModel,
  GridToolbarColumnsButton,
  GridToolbarExport,
  GridToolbarFilterButton,
  getGridStringOperators,
  useGridApiRef,
  GridCellParams,
} from '@mui/x-data-grid-pro';
import PipelineReviewContext from '../../contexts/PipelineReviewContext';
import { FlexBox } from 'components/FlexBox';
import { renderCellBooleanCheck } from 'components/grid/GridCellBooleanCheck';
import { PipelineRulesModal } from './PipelineRulesModal/PipelineRulesModal';
import { WhitelistIssuesModal } from './WhitelistIssuesModal';

const BOOLEAN_FILTER_OPERATORS = [
  ...getGridStringOperators().filter(op => ['isEmpty', 'isNotEmpty'].includes(op.value)),
  {
    label: 'is true',
    value: 'isTrue',
    getApplyFilterFn: () => null,
  },
  {
    label: 'is false',
    value: 'isFalse',
    getApplyFilterFn: () => null,
  },
];

const HIDDEN_COLUMN_PATTERNS = {
  suffixes: ['_id', '_ids', '_flag'],
  prefixes: ['is_'],
} as const;

const shouldHideColumn = (key: string): boolean => {
  return (
    HIDDEN_COLUMN_PATTERNS.suffixes.some(suffix => key.endsWith(suffix)) ||
    HIDDEN_COLUMN_PATTERNS.prefixes.some(prefix => key.startsWith(prefix))
  );
};

const ISSUE_COLUMNS = ['issues', 'pre_consolidation_issues', 'whitelisted_issues'] as const;
const BASE_HIDDEN_COLUMNS = [
  'pipeline_id',
  'issues_count',
  'pre_consolidation_issues',
  'whitelisted_issues',
  'row_version',
];
const COLUMN_ORDER = [
  'has_issues',
  'issues',
  'pre_consolidation_issues',
  'whitelisted_issues',
  'entity_name',
  'pipeline_name',
];
const HIGHLIGHT_EXCLUDED_COLUMNS = [
  'has_issues',
  'issues',
  'whitelisted_issues',
  'entity_name',
  'pipeline_name',
  'types',
  'row_version',
  'data_source',
  'step_names',
];

export const PipelineReviewGrid = ({ loadingProps }: { loadingProps: LoadingProps }) => {
  const { accessToken } = useAuth();
  const apiRef = useGridApiRef();

  const [, setLoadingState] = loadingProps;
  const [rowCount, setRowCount] = useState(0);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 100,
  });
  const { filterState, pipelineReviewParams, pipelineReviewState, resetPaginationModel, setResetPaginationModel } =
    useContext(PipelineReviewContext);

  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [rows, setRows] = useState<ReadonlyArray<PipelineReviewRecord>>([]);
  const [queryOptions, setQueryOptions] = React.useState<GridFilterModel>({ items: [] });
  const [filterValues, setFilterValues] = React.useState<object[]>([]);
  const [sortValues, setSortValues] = React.useState<string[]>([]);
  const [rowSelection, setRowSelection] = useState<GridRowSelectionModel>([]);
  const [open, setOpen] = useState(false);

  const selectedRowIds = useMemo(
    () =>
      rowSelection
        .map(selectionId => rows.find(row => row.id === selectionId)?.row_id)
        .filter((id): id is string => id !== undefined),
    [rowSelection, rows]
  );

  const selectedRowIssues = useMemo(
    () =>
      rows
        .filter(row => rowSelection.includes(row.id))
        .map(row => {
          const issues = Array.isArray(row.issues) ? row.issues : [];
          const preConsolidationIssues = Array.isArray(row.pre_consolidation_issues)
            ? row.pre_consolidation_issues
            : [];

          const combinedIssues = Array.from(new Set([...issues, ...preConsolidationIssues]));

          return {
            rowId: row.row_id,
            issueIds: combinedIssues,
            pipelineId: row.pipeline_id,
          };
        }),
    [rows, rowSelection]
  );

  const canWhitelist = useMemo(
    () => pipelineReviewState.stage?.name.toLowerCase() === 'selection' && Boolean(pipelineReviewState.entity),
    [pipelineReviewState.stage, pipelineReviewState.entity]
  );

  const renderCellIssueNames = useCallback(
    (params: GridRenderCellParams) => {
      const issues = params.value;

      if (!issues || !Array.isArray(issues)) return '';

      return issues
        .map(issueId => {
          const issueName = filterState.issues.find(i => i.issueId === issueId)?.name;
          return issueName ? issueName : '';
        })
        .filter(Boolean)
        .join(', ');
    },
    [filterState.issues]
  );

  const getCellClassName = useCallback(
    (params: GridCellParams) => {
      const row = params.row;
      const fieldName = params.field;

      const allIssuesText = [...(Array.isArray(row.issues) ? row.issues : [])]
        .map(issueId => filterState.issues.find(i => i.issueId === issueId)?.name || '')
        .join(' ')
        .toLowerCase();

      return allIssuesText.includes(fieldName.toLowerCase()) ? 'warning-cell' : '';
    },
    [filterState.issues]
  );

  useEffect(() => {
    return LoadState(setLoadingState, async () => {
      if (!accessToken || pipelineReviewParams === null) {
        setRowCount(0);
        setRows([]);
        setColumns([]);
        return;
      }

      const queryParams: PipelineReviewConfig = { ...pipelineReviewParams };
      queryParams['pageNumber'] = (paginationModel.page + 1).toString();
      queryParams['pageSize'] = paginationModel.pageSize.toString();

      if (filterValues.length > 0) {
        queryParams['filterBy'] = filterValues.reduce((acc: FilterParamsConfig, obj) => {
          const key = Object.keys(obj)[0] as keyof typeof obj;
          return { ...acc, [key]: obj[key] };
        }, {});
      }

      if (sortValues.length > 0) {
        queryParams['sortBy'] = sortValues;
      }

      const data = await GetPipelineReview(queryParams, accessToken);

      if (data.length === 0) {
        setRowCount(0);
        setRows([]);
        return;
      }

      const firstRow = data[0];
      const hiddenByDefaultColumns = new Set([
        ...BASE_HIDDEN_COLUMNS,
        ...Object.keys(firstRow).filter(shouldHideColumn),
      ]);
      const nonHighlightColumnsSet = new Set([...Array.from(hiddenByDefaultColumns), ...HIGHLIGHT_EXCLUDED_COLUMNS]);

      const headers: GridColDef[] = Object.entries(firstRow)
        .filter(([key]) => key !== 'needs_review')
        .map(([key, value]) => {
          const headerProps: GridColDef = {
            field: key,
            headerName: humanize(key),
            width: 125,
          };

          if (typeof value === 'boolean' || key.startsWith('is_') || key.endsWith('_flag')) {
            headerProps.renderCell = renderCellBooleanCheck;
            headerProps.align = 'center';
            headerProps.filterOperators = BOOLEAN_FILTER_OPERATORS;
          }

          if (ISSUE_COLUMNS.includes(key as (typeof ISSUE_COLUMNS)[number])) {
            headerProps.renderCell = renderCellIssueNames;
          }

          if (!key.endsWith('_date') && !nonHighlightColumnsSet.has(key)) {
            headerProps.cellClassName = getCellClassName;
          }

          return headerProps;
        });

      headers.push({
        field: 'has_issues',
        headerName: 'Has Issues',
        width: 100,
        renderCell: renderCellBooleanCheck,
        align: 'center',
        filterOperators: BOOLEAN_FILTER_OPERATORS,
      });

      headers.sort((a, b) => {
        const aIsDate = a.field.endsWith('_date');
        const bIsDate = b.field.endsWith('_date');
        if (aIsDate !== bIsDate) return aIsDate ? 1 : -1;

        const aIndex = COLUMN_ORDER.indexOf(a.field);
        const bIndex = COLUMN_ORDER.indexOf(b.field);
        if (aIndex === -1 && bIndex === -1) return 0;
        if (aIndex === -1) return 1;
        if (bIndex === -1) return -1;
        return aIndex - bIndex;
      });

      const columnsChanged =
        columns.length !== headers.length || columns.some((col, index) => col.field !== headers[index].field);

      setColumns(headers);

      if (columnsChanged || Object.keys(apiRef.current.state.columns.columnVisibilityModel).length === 0) {
        const visibilityModel = headers.reduce(
          (acc, { field }) => ({
            ...acc,
            [field]: !hiddenByDefaultColumns.has(field),
          }),
          {}
        );

        apiRef.current.setColumnVisibilityModel(visibilityModel);
      }

      setRowCount(
        data.length < paginationModel.pageSize
          ? data.length + paginationModel.page * paginationModel.pageSize
          : Number.MAX_VALUE
      );
      setRows(
        data.map((row, index) => ({
          ...row,
          id: index,
          has_issues: Array.isArray(row.issues) && row.issues.length > 0,
        }))
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    setLoadingState,
    accessToken,
    pipelineReviewParams,
    paginationModel,
    filterValues,
    sortValues,
    renderCellIssueNames,
    apiRef,
    getCellClassName,
  ]);

  useEffect(() => {
    if (resetPaginationModel === true) {
      setPaginationModel({
        page: 0,
        pageSize: 100,
      });
      setResetPaginationModel(false);
    }
  }, [resetPaginationModel, setResetPaginationModel]);

  function humanize(str: string) {
    return str
      .replace(/^[\s_]+|[\s_]+$/g, '')
      .replace(/[_\s]+/g, ' ')
      .replace(/^[a-z]/, function (m) {
        return m.toUpperCase();
      });
  }

  const handleEvent: GridEventListener<'preferencePanelClose'> = (params: GridPreferencePanelParams) => {
    if (params['openedPanelValue'] === 'filters') {
      let filterObjects: object[] = [];
      queryOptions.items.forEach(item =>
        filterObjects.push({ [`${item.field}_${item.operator}`]: item.value || true })
      );
      setFilterValues(filterObjects);
    }
  };

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    setQueryOptions({ ...filterModel });
  }, []);

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    setSortValues(sortModel.map(item => `${item.field}_${item.sort}`));
  }, []);

  function CustomFooter() {
    return (
      <FlexBox flexDirection={'row-reverse'}>
        <GridPagination />
        <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
      </FlexBox>
    );
  }

  return (
    <FlexTableBox sx={{ gap: 1 }}>
      <FlexTableBox>
        <FlexBox flexDirection={'row-reverse'}>
          <PipelineRulesModal
            canOpen={rowSelection.length > 0 || filterValues.length > 0}
            open={open}
            setOpen={setOpen}
            allRows={null}
            selectedRowIds={selectedRowIds}
            selectedEntity={pipelineReviewState.entity}
            selectedPipeline={null}
            selectedStage={filterState.stages.find(s => s.name === 'transformation') ?? pipelineReviewState.stage}
            setRowSelection={setRowSelection}
          />
          <WhitelistIssuesModal
            selectedEntity={pipelineReviewState.entity}
            canWhitelist={canWhitelist}
            rowIssues={selectedRowIssues}
          />
        </FlexBox>
        <CompactGridWrapper
          apiRef={apiRef}
          columns={columns}
          rows={rows}
          rowHeight={30}
          pagination
          rowCount={rowCount}
          paginationModel={paginationModel}
          pageSizeOptions={[100, 250, 500]}
          paginationMode='server'
          onPaginationModelChange={setPaginationModel}
          disableRowSelectionOnClick
          checkboxSelection
          onRowSelectionModelChange={newSelection => {
            setRowSelection(newSelection);
          }}
          rowSelectionModel={rowSelection}
          filterMode='server'
          onFilterModelChange={onFilterChange}
          sortingMode='server'
          onSortModelChange={handleSortModelChange}
          filterDebounceMs={250}
          onPreferencePanelClose={handleEvent}
          slots={{
            footer: CustomFooter,
          }}
          slotProps={{
            filterPanel: {
              // Force usage of "And" operator
              logicOperators: [GridLogicOperator.And],
            },
            baseCheckbox: {
              size: 'small',
            },
          }}
        />
      </FlexTableBox>
    </FlexTableBox>
  );
};
