import { appSettings } from 'AppSettings';
import { PatientReviewScore, PatientReview, ReviewStatus } from './PatientReviewData';
import { BaseGet } from './BaseData';
import { MeasurementNormality } from './MeasurementDefinitionData';
import assertExhaustive from '../researchProjectManagement/patientApproval/utils/assertExhaustive';

export interface PatientApprovalPatient {
  researchProjectId: string;
  deidentity: string;
  yearOfBirth?: number;
  state?: string;
  sampleBiobanks: string[];

  statisticTotalCount: number;

  labTotalCount: number;
  labCuratedTotalCount: number;
  labTotalWithIssuesCount: number;
  labCuratedTotalWithIssuesCount: number;
  labTotalWithoutIssuesCount: number;
  labCuratedTotalWithoutIssuesCount: number;

  obsTotalCount: number;
  obsCuratedTotalCount: number;
  obsTotalWithIssuesCount: number;
  obsCuratedTotalWithIssuesCount: number;
  obsTotalWithoutIssuesCount: number;
  obsCuratedTotalWithoutIssuesCount: number;

  medTotalCount: number;
  medCuratedTotalCount: number;
  medTotalWithIssuesCount: number;
  medTotalWithoutIssuesCount: number;
  medCuratedTotalWithIssuesCount: number;
  medCuratedTotalWithoutIssuesCount: number;

  procTotalCount: number;
  procTotalWithIssuesCount: number;
  procTotalWithoutIssuesCount: number;

  problemTotalCount: number;
  problemTotalWithIssuesCount: number;
  problemTotalWithoutIssuesCount: number;

  patientNotes: string[];
  sampleNotes: string[];

  reviewStatusId: ReviewStatus['reviewStatusId'] | null;
  reviewScore: PatientReviewScore | null;
  reviewNotes: string | null;
}

export interface PatientApprovalPatientTest {
  test: string;
  type: string;
  codes: string[];
}

export interface PatientApprovalPatientWeeklyCount {
  performedWeek: string;
  type: string;
  totalCount: number;
  noIssuesCount: number;
}

export interface PatientApprovalPatientMeasurement {
  type: string;
  performedWeek: string;
  test: string;
  codes: string[];
  average: number;
  units: string;
  normality: MeasurementNormality;
  issues: string[];
}

export const PATIENT_APPROVAL_EVENT_TYPES = ['diagnosis', 'medication'] as const;
export type PatientApprovalEventType = (typeof PATIENT_APPROVAL_EVENT_TYPES)[number];

export interface PatientApprovalEvent {
  name: string;
  eventDate: string;
  type: PatientApprovalEventType;
  issues: string[];
}

export interface PatientApprovalSampleCollectionDate {
  sampleCount: number;
  collectionDate: string;
}

export const PATIENT_JOURNEY_ENTITIES = [
  'problem',
  'observation',
  'medication',
  'procedure',
  'lab',
  'pathReport',
] as const;
export type PatientJourneyEntity = (typeof PATIENT_JOURNEY_ENTITIES)[number];

export const getPatientJourneyEntityDisplayName = (entity: PatientJourneyEntity) => {
  switch (entity) {
    case 'lab':
      return 'Labs';
    case 'medication':
      return 'Medications';
    case 'observation':
      return 'Observations';
    case 'problem':
      return 'Problems';
    case 'procedure':
      return 'Procedures';
    case 'pathReport':
      return 'Path Reports';
    default:
      assertExhaustive(entity);
  }
};

export interface PatientJourneyRow {
  id: string;
  date: string;
  entity: PatientJourneyEntity;
  vocabulary: string | null;
  vocabularyCode: string | null;
  name: string;
  quanValue: number | null;
  qualValue: string | null;
  units: string | null;
  issues: string[];
}

export interface PatientApprovalPatientData {
  tests: PatientApprovalPatientTest[];
  weeklyCounts: PatientApprovalPatientWeeklyCount[];
  measurements: PatientApprovalPatientMeasurement[];
  events: PatientApprovalEvent[];
  sampleCollectionDates: PatientApprovalSampleCollectionDate[];
  patientJourney: PatientJourneyRow[];
  review: PatientReview | null;
}

export async function GetResearchProjectApprovalPatients(
  researchProjectId: string,
  accessToken: string | undefined
): Promise<PatientApprovalPatient[] | undefined> {
  return await BaseGet(
    `${appSettings.api.endpoint}/api/v2/PatientApproval/ResearchProject/${researchProjectId}/Patients`,
    accessToken
  );
}

export async function GetPatientTests(
  researchProjectId: string,
  deidentity: string,
  accessToken: string | undefined
): Promise<PatientApprovalPatientTest[] | undefined> {
  return await BaseGet(
    `${
      appSettings.api.endpoint
    }/api/v2/PatientApproval/ResearchProject/${researchProjectId}/Patient/${encodeURIComponent(deidentity)}/Tests`,
    accessToken
  );
}
export async function GetPatientWeeklyCounts(
  researchProjectId: string,
  deidentity: string,
  accessToken: string | undefined
): Promise<PatientApprovalPatientWeeklyCount[] | undefined> {
  return await BaseGet(
    `${
      appSettings.api.endpoint
    }/api/v2/PatientApproval/ResearchProject/${researchProjectId}/Patient/${encodeURIComponent(
      deidentity
    )}/WeeklyCounts`,
    accessToken
  );
}
export async function GetPatientMeasurements(
  researchProjectId: string,
  deidentity: string,
  accessToken: string | undefined
): Promise<PatientApprovalPatientMeasurement[] | undefined> {
  return await BaseGet(
    `${
      appSettings.api.endpoint
    }/api/v2/PatientApproval/ResearchProject/${researchProjectId}/Patient/${encodeURIComponent(
      deidentity
    )}/Measurements`,
    accessToken
  );
}
export async function GetPatientEvents(
  researchProjectId: string,
  deidentity: string,
  accessToken: string | undefined
): Promise<PatientApprovalEvent[] | undefined> {
  return await BaseGet(
    `${
      appSettings.api.endpoint
    }/api/v2/PatientApproval/ResearchProject/${researchProjectId}/Patient/${encodeURIComponent(deidentity)}/Events`,
    accessToken
  );
}
export async function GetSampleCollectionDates(
  deidentity: string,
  accessToken: string | undefined
): Promise<PatientApprovalSampleCollectionDate[] | undefined> {
  return await BaseGet(
    `${appSettings.api.endpoint}/api/v2/PatientApproval/Patient/${encodeURIComponent(
      deidentity
    )}/SampleCollectionDates`,
    accessToken
  );
}
export async function GetPatientJourney(
  researchProjectId: string,
  deidentity: string,
  accessToken: string | undefined
): Promise<PatientJourneyRow[] | undefined> {
  return await BaseGet(
    `${
      appSettings.api.endpoint
    }/api/v2/PatientApproval/ResearchProject/${researchProjectId}/Patient/${encodeURIComponent(deidentity)}/Journey`,
    accessToken
  );
}

export async function GetPatientLatestReview(
  researchProjectId: string,
  deidentity: string,
  accessToken: string | undefined
): Promise<PatientReview | undefined> {
  return await BaseGet(
    `${
      appSettings.api.endpoint
    }/api/v2/PatientApproval/ResearchProject/${researchProjectId}/Patient/${encodeURIComponent(
      deidentity
    )}/LatestReview`,
    accessToken
  );
}
