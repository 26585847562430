import { appSettings } from 'AppSettings';
import { BaseGet } from './BaseData';
import { GetBiobanks } from './BiobankData';
import { GetLabs } from './LabData';
import { SampleType } from './SampleTypeData';
import { TransitionType } from './SampleTrackingData';

export const ZYMO_RESEARCH_PICKLIST_NAME = 'Zymo Research (Lab)';
export const GALATEA_BIO_PICKLIST_NAME = 'Galatea Bio (Biobank)';

export interface SubsetType {
  name: string;
  displayName: string;
}

export interface ExtractionKit {
  extractionKitId: string;
  name: string;
}

export interface ExtractionPlatform {
  extractionPlatformId: string;
  name: string;
}

export interface ExtractionType {
  name: string;
  displayName: string;
}

export interface LibraryKit {
  libraryKitId: string;
  name: string;
}

export interface SequencingExpectedCoverage {
  name: string;
}

export interface SequencingPlatform {
  sequencingPlatformId: string;
  name: string;
}

export interface ShippingCarrier {
  shippingCarrierId: string;
  name: string;
}

export interface Temperature {
  temperatureId: string;
  name: string;
}

export interface VolumeUnit {
  volumeUnitId: string;
  name: string;
  unitOfMeasure: string;
}

export interface ContainerType {
  containerTypeId: string;
  name: string;
}

export interface CheckByFailedReason {
  transitionEnumId: string;
  name: string;
  displayName: string;
}

export type OrganizationType = 'Biobank' | 'Lab';

export interface Organization {
  name: string;
  id: string;
  type: OrganizationType;
}

export type ExhaustionApproach = 'none' | 'discard' | 'exhaust' | 'rebiobank';
export const DefaultExhaustionApproachValues: ReadonlyArray<ExhaustionApproach> = [
  'none',
  'discard',
  'exhaust',
  'rebiobank',
];

export type SampleIdResolverApproach = 'normal' | 'historic';

export type SampleActionOnCheckBy = 'supportsOutputSamples' | 'notSupported' | 'requiresOutputSamples' | 'relabels';
export const SampleActionOnCheckByValues: ReadonlyArray<SampleActionOnCheckBy> = [
  'supportsOutputSamples',
  'notSupported',
  'requiresOutputSamples',
  'relabels',
];

export interface TransitionEnum {
  name: TransitionType;
  transitionEnumId: string;
  defaultExhaustionApproach: ExhaustionApproach;
  requireCheckByToEnter: boolean;
  sampleIdResolverApproach: SampleIdResolverApproach;
  defaultSampleActionOnCheckBy: SampleActionOnCheckBy;
  defaultEnableCheckByFailedReason: boolean;
  defaultEnableSampleExhaustion: boolean;
}

export interface SampleAvailabilityType {
  sampleAvailabilityTypeId: string;
  name: string;
  isExhaustedState: boolean;
}

export async function GetSubsetType(accessToken?: string): Promise<ReadonlyArray<SubsetType>> {
  return await BaseGet(`${appSettings.api.endpoint}/api/v2/ReferenceData/SubsetType`, accessToken);
}

export async function GetExtractionKit(accessToken?: string): Promise<ReadonlyArray<ExtractionKit>> {
  return await BaseGet(`${appSettings.api.endpoint}/api/v2/ReferenceData/ExtractionKit`, accessToken);
}

export async function GetExtractionPlatform(accessToken?: string): Promise<ReadonlyArray<ExtractionPlatform>> {
  return await BaseGet(`${appSettings.api.endpoint}/api/v2/ReferenceData/ExtractionPlatform`, accessToken);
}

export async function GetExtractionType(accessToken?: string): Promise<ReadonlyArray<ExtractionType>> {
  return await BaseGet(`${appSettings.api.endpoint}/api/v2/ReferenceData/ExtractionType`, accessToken);
}

export async function GetLibraryKit(accessToken?: string): Promise<ReadonlyArray<LibraryKit>> {
  return await BaseGet(`${appSettings.api.endpoint}/api/v2/ReferenceData/LibraryKit`, accessToken);
}

export async function GetSequencingExpectedCoverage(
  accessToken?: string
): Promise<ReadonlyArray<SequencingExpectedCoverage>> {
  return await BaseGet(`${appSettings.api.endpoint}/api/v2/ReferenceData/SequencingExpectedCoverage`, accessToken);
}

export async function GetSequencingPlatform(accessToken?: string): Promise<ReadonlyArray<SequencingPlatform>> {
  return await BaseGet(`${appSettings.api.endpoint}/api/v2/ReferenceData/SequencingPlatform`, accessToken);
}

export async function GetTransitionEnum(accessToken?: string): Promise<ReadonlyArray<TransitionEnum>> {
  return await BaseGet(`${appSettings.api.endpoint}/api/v2/ReferenceData/TransitionEnum`, accessToken);
}

export async function GetSampleAvailabilityType(accessToken?: string): Promise<ReadonlyArray<SampleAvailabilityType>> {
  return await BaseGet(`${appSettings.api.endpoint}/api/v2/ReferenceData/SampleAvailabilityType`, accessToken);
}

export async function GetSampleTypes(accessToken?: string): Promise<ReadonlyArray<SampleType>> {
  return await BaseGet(`${appSettings.api.endpoint}/api/v2/ReferenceData/SampleType`, accessToken);
}

export async function GetShippingCarrier(accessToken?: string): Promise<ReadonlyArray<ShippingCarrier>> {
  return await BaseGet(`${appSettings.api.endpoint}/api/v2/ReferenceData/ShippingCarrier`, accessToken);
}

export async function GetTemperature(accessToken?: string): Promise<ReadonlyArray<Temperature>> {
  return await BaseGet(`${appSettings.api.endpoint}/api/v2/ReferenceData/Temperature`, accessToken);
}

export async function GetVolumeUnit(accessToken?: string): Promise<ReadonlyArray<VolumeUnit>> {
  return await BaseGet(`${appSettings.api.endpoint}/api/v2/ReferenceData/VolumeUnit`, accessToken);
}

export async function GetContainerType(accessToken?: string): Promise<ReadonlyArray<ContainerType>> {
  return await BaseGet(`${appSettings.api.endpoint}/api/v2/ReferenceData/ContainerType`, accessToken);
}

export async function GetCheckByFailedReason(accessToken?: string): Promise<ReadonlyArray<CheckByFailedReason>> {
  return await BaseGet(`${appSettings.api.endpoint}/api/v2/ReferenceData/CheckByFailedReason`, accessToken);
}

export async function GetOrganizations(accessToken?: string): Promise<ReadonlyArray<Organization>> {
  const biobanks = await GetBiobanks(accessToken);
  const labs = await GetLabs(accessToken);

  return biobanks
    .map(b => {
      return {
        name: b.name,
        id: b.biobankId,
        type: 'Biobank' as OrganizationType,
      };
    })
    .concat(
      labs.map(l => {
        return {
          name: l.name,
          id: l.labId,
          type: 'Lab' as OrganizationType,
        };
      })
    );
}

export function getOrganizationLabel(o?: Organization) {
  return o && `${o.name} (${o.type})`;
}
